import CardList from "./cardGameList";

const CardGame = () => {
  return (
    <>
      <CardList />
    </>
  );
};
export default CardGame;
